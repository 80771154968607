import React, { ReactNode } from "react"

import {
  IconCheckCircle,
  IconCircleDashed,
  IconWarningCircle,
} from "@supernovaio/icons"

import { cn } from "../../utils/cn"
import { DMIcon } from "../DMIcon"

type Props = {
  children: ReactNode
}

export function DMWizardNavigation(props: Props) {
  // We need to interleave the children with dashed lines, so we need to have an array
  const children = React.Children.toArray(props.children)

  return (
    <div className="flex flex-row items-center">
      {children.flatMap((item, index) => {
        return [
          item,
          index < children.length - 1 ? (
            <hr
              key={index}
              // The selected item has a 2px border, while the regular one only has 1px. We offset this
              // by including 1px padding to the regular one, so the layout doesn't jump, but that also
              // means there would be a gap between this dashed line and the item, so we need -1 margin
              className="border-neutral border-dashed mx-[-1px] w-[18px] h-1"
            />
          ) : null,
        ]
      })}
    </div>
  )
}

type ItemProps = {
  fixedWidth?: number
  title: string
  description?: string
  isDisabled?: boolean

  /** Whether we show the dashed / checked circle icon. Defaults to false. */
  showCompletionIcon?: boolean
  isCompleted?: boolean

  isSelected?: boolean
  onSelected: () => void

  isError?: boolean

  dataTestId?: string
}

export function DMWizardNavigationItem(props: ItemProps) {
  return (
    // The z-index is necessary, so that the dashed line is behind the selection border.
    // The selected item has a 2px border, while the regular one only has 1px. We offset this
    // by including 1px padding to the regular one, so the layout doesn't jump
    <div
      className={cn("z-2", !props.isSelected && "p-1")}
      style={{ width: props.fixedWidth }}
    >
      <button
        type="button"
        disabled={props.isDisabled}
        className={cn(
          "flex flex-row w-full pl-12 pr-[10px] py-[7px] text-left items-center gap-12 justify-between",
          "rounded border border-neutral outline-none ring-offset-2 focus-visible:ring-2",
          props.isSelected && "border-2 border-primary"
        )}
        onClick={props.onSelected}
        data-test-id={props.dataTestId}
      >
        <div className="flex flex-col">
          <p
            className={cn(
              "text-body text-neutral font-bold",
              props.isDisabled && "text-disabled"
            )}
          >
            {props.title}
          </p>
          {props.description && (
            <p
              className={cn(
                "text-body-small text-neutral-faded font-semibold",
                props.isDisabled && "text-disabled"
              )}
            >
              {props.description}
            </p>
          )}
        </div>

        <IconSlot {...props} />
      </button>
    </div>
  )
}

type IconSlotProps = Pick<
  ItemProps,
  "isDisabled" | "showCompletionIcon" | "isCompleted" | "isError"
>

function IconSlot(props: IconSlotProps) {
  if (props.isError) {
    return (
      <DMIcon
        svg={IconWarningCircle}
        color={props.isDisabled ? "disabled" : "critical"}
        size="medium"
      />
    )
  }

  if (props.showCompletionIcon) {
    if (props.isCompleted) {
      return (
        <DMIcon
          svg={IconCheckCircle}
          color={props.isDisabled ? "disabled" : "neutral"}
          size="medium"
        />
      )
    }
    return (
      <DMIcon
        svg={IconCircleDashed}
        color={props.isDisabled ? "disabled" : "neutral-placeholder"}
        size="medium"
      />
    )
  }

  return null
}
