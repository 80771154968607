"use client"

import React, { useEffect, useState } from "react"

import { DMBadge, DMSupernovaLogo } from "@supernovaio/dm"
import { DMIcon } from "@supernovaio/dm/src/components/DMIcon"
import { cn } from "@supernovaio/dm/src/utils/cn"
import { verifyImage } from "@supernovaio/dm/src/utils/verifyImage"

import circleContainerSvg from "@supernovaio/assets/illustrations/circle_container.svg"
import circleContainerDarkSvg from "@supernovaio/assets/illustrations/circle_container_dark.svg"
import { IconCheck } from "@supernovaio/icons"

import { DMImage } from "../DMImage"

type DMCardTileProps = React.PropsWithChildren<{
  title: string
  description?: string
  truncateDescription?: boolean
  bullets?: string[]
  actionsSlot?: React.ReactNode
  badgesSlot?: React.ReactNode
  titleBadgesSlot?: React.ReactNode
  /** @deprecated Use titleBadgeSlot instead */
  titleBadgeText?: string
  hasHoverEffect?: boolean
  thumbnail: string | React.ComponentType | React.ReactElement
}>

function ThumbnailWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div className="absolute bottom-[32px] right-[32px] flex h-[40px] w-[40px] items-center justify-center">
      {children}
    </div>
  )
}

function ThumbnailRemoteImage({ url }: { url: string }) {
  const [ifVerified, setIsVerified] = useState<boolean | undefined>()

  useEffect(() => {
    verifyImage(url)
      .then(() => {
        setIsVerified(true)
      })
      .catch(() => {
        setIsVerified(false)
      })
  }, [url])

  if (typeof ifVerified === "undefined") {
    return null
  }

  return (
    <ThumbnailWrapper>
      {ifVerified ? (
        <DMImage
          image={{ src: url, height: 40, width: 40 }}
          className="block h-full w-full object-cover rounded-full"
        />
      ) : (
        <DMSupernovaLogo size="medium" type="symbol" />
      )}
    </ThumbnailWrapper>
  )
}

export function renderThumbnail(thumbnail: DMCardTileProps["thumbnail"]) {
  if (typeof thumbnail === "string") {
    return <ThumbnailRemoteImage url={thumbnail} />
  }

  if (typeof thumbnail === "function") {
    const Component = thumbnail

    return (
      <ThumbnailWrapper>
        <Component />
      </ThumbnailWrapper>
    )
  }

  return <ThumbnailWrapper>{thumbnail}</ThumbnailWrapper>
}

export function DMCardTile({
  title,
  description,
  bullets,
  actionsSlot,
  badgesSlot,
  titleBadgesSlot, 
  titleBadgeText,
  thumbnail,
  children,
  truncateDescription = true,
  hasHoverEffect = false,
}: DMCardTileProps) {
  const IconElement = renderThumbnail(thumbnail)

  return (
    <div
      className={cn(
        "inner-border-neutral-faded bg-elevation-raised inner-border flex flex-row rounded",
        hasHoverEffect &&
          "hover:bg-neutral-faded hover:shadow-none transition-colors"
      )}
    >
      <div className="relative flex-1 py-24 pl-24 pr-[140px]">
        <div className="flex flex-col gap-20 justify-between h-full">
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-wrap items-center">
                <h4 className="inline-flex text-title-4 text-neutral mr-8">
                  {title}
                </h4>
                {(titleBadgesSlot || titleBadgeText) && (
                  <div className="flex gap-4 flex-wrap">
                    {titleBadgesSlot}
                    {titleBadgeText && (
                      <DMBadge size="small" color="lightGrey">
                        {titleBadgeText}
                      </DMBadge>
                    )}
                  </div>
                )}
              </div>
              {badgesSlot && (
                <div className="flex gap-4 flex-wrap">{badgesSlot}</div>
              )}
            </div>
            <div
              className={cn(
                "text-body text-neutral-faded",
                truncateDescription ? "line-clamp-2" : ""
              )}
            >
              {description}
            </div>

            {bullets &&
              bullets.map((bullet) => (
                <div
                  key={bullet}
                  className="text-body text-neutral-faded flex items-start gap-8"
                >
                  <DMIcon
                    color="neutral-faded"
                    size="small"
                    svg={IconCheck}
                    className="mt-2"
                  />
                  {bullet}
                </div>
              ))}
            {children && (
              <div className="text-body text-neutral-faded flex items-center">
                {children}
              </div>
            )}
          </div>

          {actionsSlot && (
            <div className="flex flex-wrap gap-x-12 gap-y-8">{actionsSlot}</div>
          )}
        </div>

        <div className="absolute bottom-0 right-0 w-[140px] h-[140px]">
          <DMImage
            src={circleContainerSvg}
            srcDark={circleContainerDarkSvg}
            className="absolute top-0 left-0"
          />
          {IconElement}
        </div>
      </div>
    </div>
  )
}
